body.dark .Licensing {
  textarea {
    background-color: #000000 !important;
    color: rgb(255, 255, 255) !important;
    border-color: #242435 !important;
  }
}

.Licensing {

  @media screen and (max-width: 768px) {
    margin-left: 0px !important;
    max-width: 100%;
  }

  .bodyHeader {
    border-bottom: 1px solid #d0d4de;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 18px;
  }

  .CheckBox_ {
    border-bottom: 1px solid #d0d4de;
    padding: 16px;

    &.disabled {
      color: #28293980 !important;

      .rc-checkbox:hover .rc-checkbox-inner {
        border: 2px solid #a3acc7 !important;
      }

      .rc-checkbox-inner {
        background-color: #adb8d8 !important;
        border: 2px solid #a3acc7 !important;

        &::after {
          border-color: #adb8d8;
        }
      }

      &.checked {
        color: inherit !important;

        .rc-checkbox-inner {
          &::after {
            border-color: white;
          }
        }
      }
    }
  }
}
