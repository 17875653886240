.AudioPlayer {
  --pause: #384f92;
  --timer: #6d737b;
  --bar-bg: #c5dcfc;
}

body.dark .AudioPlayer {
  --pause: #313363;
  --timer: #fff;
  --bar-bg: #fff;
}

.AudioPlayer {
  align-items: center;
  display: flex;
  width: 250px;

  .play_pause {
    box-sizing: content-box;
    height: 26px;
    cursor: pointer;
    background-color: var(--pause) !important;
    height: 16px;
    padding: 10px;
    border-radius: 50%;
    margin-right: 18px;

    &.disabled {
      background-color: #fff !important;
      opacity: 0.6;
      cursor: auto;
    }

    @media screen and (max-width: 768px) {
      margin-right: 10px;
      height: 14px;
    }
  }

  .timer {
    color: var(--timer);
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
      margin-left: 8px;
    }
  }

  .progress {
    --seek-before-width: 0;
    --seek-before-color: #9bafca;
    --knobby: #3452a5;
    --selectedKnobby: #26c9c3;

    appearance: none;
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 6px;
    outline: none;
    margin-right: 10px;
  }

  .progress::-webkit-slider-runnable-track {
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 6px;
    outline: none;
  }

  .progress::-moz-range-track {
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 6px;
    outline: none;
  }

  // .progress::-moz-focus-outer {
  //   border: 0;
  // }

  .progress::before {
    content: "";
    height: 6px;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  .progress::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 6px;
  }

  .progress::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -2px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
  }

  .progress:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }

  .progress::-moz-range-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }

  .progress:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
}
