body.dark .BannerMessage {
  textarea {
    background-color: #000000 !important;
    color: rgb(255, 255, 255) !important;
    border-color: #242435 !important;
  }
}

.BannerMessage {
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 150px) !important;
  width: 725px !important;
  margin: auto;
  margin-top: 0;

  .lang-select {
    max-width: 100%;
    * {
      max-width: 100%;
    }
  }

  h2 small {
    font-size: 15px;
    color: red;

    &.active {
      color: green;
    }
  }

  > * {
    width: 100%;
  }

  div.text {
    position: relative;
    margin-bottom: 10px;

    textarea {
      width: 100%;
      resize: vertical !important;
      overflow: visible;
      border-radius: 10px !important;
      padding: 16px 19px !important;
      line-height: 18px !important;
      font-size: 14px !important;
      border-color: #e1e6ed !important;
      margin-bottom: 16px;
      min-height: 125px;
    }

    span {
      position: absolute;
      right: 0;
      bottom: -10px;
      font-size: 13px;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }

  > span {
    opacity: 0.5;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    justify-content: end;
    text-transform: uppercase;

    .deactivate,
    .deactivate:focus,
    .deactivate:hover {
      color: #313363 !important;
      background-color: #fff !important;
    }

    .activate {
      margin-left: 14px;
    }

    @media screen and (max-width: 768px) {
      justify-content: space-between;

      button {
        width: 48% !important;
      }
    }
  }
}

body.dark {
  .BannerMessage {
    span {
      color: #ffffff;
    }
  }
  .deactivate {
    color: #fff !important;
    background-color: #3a3a49 !important;
  }
}
