@import "./colors.scss";

body.dark {
  color: $silver;

  .contentContainer {
    background-color: $jaguar !important;
  }

  .authPageContainer {
    .contentContainer {
      background-color: transparent !important;
    }
  }

  .sideBarContainer {
    .sidenav---sidenav-nav---3tvij > div {
      background: $black;
    }

    div[role="menu"] {
      background: $black;
    }
  }

  .contactsListContainer {
    background-color: $ink-blue;

    .headerContainer,
    .searchSectionContainer {
      background-color: $ink-blue !important;
    }

    .statusSectionContainer {
      h3 {
        color: $white;
      }

      .dropdown-menu {
        background-color: $raven !important;
      }
    }

    .searchSectionContainer {
      input {
        &::placeholder {
          color: $raven;
        }
      }
    }

    .userTab {
      border-left-color: transparent;

      &.selected {
        border-left-color: $green-light;
        background-color: #ffffff33;
      }

      h4 {
        color: $white;
      }

      .time,
      p {
        color: $silver;
      }
    }
  }

  .divider {
    border-top: 1px solid $white;
    opacity: 0.1;
  }

  .avatarContainer {
    &.loadingState {
      border: 1px solid $silver;
    }

    &.withStatus::before {
      border-color: $ink-blue;
    }
  }

  .toggleListButton {
    background-color: $payne-grey;
    color: $raven;
  }

  .searchSectionContainer {
    input {
      &::placeholder {
        color: $raven;
      }
    }
  }

  .chatSectionContainer {
    background-color: $jaguar;

    .chatHeaderContainer {
      background-color: $jaguar;
      border-bottom: 1px solid $payne-grey;

      .ongoingCallTitle {
        color: $silver;
      }

      .chatInfoContainer {
        h3 {
          color: $white;
        }

        p {
          color: $raven;
        }
      }

      .btnRounded {
        background: $tory-blue;
      }

      .disable {
        background: $raven;
      }
    }

    .message_actions {
      opacity: 0.5;
    }

    .chatMessagesContainer {
      &.searchMessage {
        p {
          color: $raven;
        }
      }

      .systemMessageContainer {
        background-color: $payne-grey;

        .systemMessage {
          color: $silver;
        }

        .messageSentTime {
          color: $shuttle-grey;
        }
      }

      .messagesGroupDateContainer {
        .divider {
          background-color: $raven !important;
        }

        .messagesGroupDate {
          color: $raven;
        }
      }
    }

    .chatFooterContainer {
      .text_container,
      .messageTextArea {
        background-color: $east-bay;
        border: none;
        color: $white;

        &::placeholder {
          color: $white;
        }

        .files {
          .number {
            color: #6d737b;
          }

          .file_item {
            background-color: #2e2e42;

            span {
              color: #b9b9b9;
            }
          }
        }
      }

      .messageTextArea {
        border-top: 2px solid #6d737b !important;
      }

      .buttons button {
        border-top: 2px solid #6d737b !important;
      }

      .sendMessageButton {
        background-color: $tory-blue;
        color: $white;
      }
    }
  }

  .modal-dialog {
    .modal-content {
      background: $jaguar;
      color: $white;

      .modal-header {
        background-color: $black;
        border-color: $black;
      }

      .modal-footer {
        border-color: $jaguar;

        .btn {
          background-color: $payne-grey;
          color: $white;

          &.btn-primary {
            background-color: $tory-blue;
          }
        }
      }
    }
  }

  .tabsHeadersContainer {
    .tabItem {
      color: $white;

      &.selected {
        border-color: $tory-blue;
      }
    }
  }

  .multiSelectDropdown {
    > button,
    > .dropdown-menu {
      background-color: $black-russian !important;
      border-color: $vulcan !important;
      color: $white !important;

      .searchSectionContainer {
        background-color: transparent !important;
        border: none;
      }

      p,
      input {
        color: $white;
      }
    }
  }

  .settingsFiltersSubmitButton {
    background-color: $payne-grey;
    color: $white;

    &.save {
      background-color: $tory-blue;
    }
  }

  .searchSectionContainer {
    background-color: #151517 !important;
    border: 1px solid $vulcan;

    input {
      color: $white;

      &::placeholder {
        color: $pale-blue-dark;
      }
    }
  }

  .groupNameInputField {
    color: $white;
    background-color: $black;
    border-color: $vulcan;

    &::placeholder {
      color: $pale-blue-dark;
    }
  }

  .contactsListSection {
    button.add {
      background: $tory-blue url(/static/media/add-member-white.4dd84184.svg)
        center center no-repeat;
    }

    button.remove {
      background: $payne-grey
        url(/static/media/remove-member-white.133aa8cc.svg) center center
        no-repeat !important;
    }

    button.add.remove,
    button.remove.remove {
      background-color: $payne-grey;
      color: $white;

      &:hover {
        background: $payne-grey
          url(/static/media/remove-member-white.133aa8cc.svg) center center
          no-repeat !important;
      }
    }
  }

  .groupsContainer .dataContainer p,
  .contactsListSection .contactInfoCellContainer p,
  .groupsWithTheSameParticipantModalDescription {
    color: $white !important;
  }

  .advancedSearchComponentContainer {
    .advancedSearch__placeholder {
      color: $raven !important;
    }

    .advancedSearch__menu {
      color: $white;
      background-color: $black !important;
      border-color: $vulcan;
    }
  }

  .settingsContainer {
    background-color: $jaguar;

    .settingsHeaderContainer {
      background-color: $black-russian !important;

      h1 {
        color: $white;
      }

      .tabsHeadersContainer {
        .tabItem {
          color: $silver;

          &.selected {
            border-color: $ink-blue;
          }
        }
      }

      .submitButton {
        border: none;
      }
    }
  }

  .appSettingsSubTitle {
    color: $silver;
  }

  .playNotificationContainer {
    p {
      color: $white;
    }
  }

  .themeButtonsContainer {
    button {
      border: none;
    }
  }

  .personal-info,
  .managePasswordContainer h1,
  .accountSettingFieldContainer p {
    color: $silver !important;

    &.errorText {
      color: $red !important;
    }
  }

  .personalInformationContainer,
  .adminContainer {
    .inputField {
      background-color: $black !important;
      color: $white !important;
      border-color: $jaguar !important;

      &[data-value="placeholder"] {
        color: $raven !important;
      }
    }
  }

  .accountSettingFieldContainer,
  .itemCreateInput {
    #select-dropdown button,
    #select-dropdown .dropdown-menu,
    #select-dropdown .dropdown-menu .optionsContainer,
    input {
      background-color: $black !important;
      color: $white !important;
      border-color: $jaguar !important;

      &::placeholder {
        color: $raven !important;
      }
    }

    button {
      border: none;
    }
  }

  .adminHeaderContainer {
    background-color: $black-russian !important;

    h1 {
      color: $white !important;
    }
  }

  .adminNavigation {
    background: $black-russian !important;
    color: $white !important;

    @media screen and (max-width: 691px) {
      .pages {
        background: $black-russian !important;
      }
    }
  }

  .usersHeaderContainer,
  .bulkUploadContainer {
    color: $white !important;

    .submitButton {
      border: none;
    }
  }

  .bulkActionsWrap {
    p {
      color: $white !important;
    }

    .submitButton {
      border: none;
    }

    input,
    select {
      background-color: $black !important;
      color: $white;
      border-color: $jaguar;

      &::placeholder {
        color: $raven;
      }
    }
  }

  .usersTable {
    td {
      color: $silver !important;
    }
  }

  .headerContainer {
    background: $black-russian !important;

    h1 {
      color: $white !important;
    }
  }

  .dropdown-menu {
    background-color: $payne-grey !important;
    border-color: $raven !important;
    color: $white !important;

    .dropdown-header {
      background-color: transparent !important;
    }

    p,
    input,
    button {
      color: $white !important;
    }

    button {
      &:hover {
        background: transparent;
      }

      &::before {
        border-color: $raven !important;
      }
    }
  }

  .sideBarRightPartContainer {
    background-color: $black;
  }

  .messageTextContainer {
    background-color: $payne-grey !important;
    color: $white;
  }

  .messageSenderName {
    color: $silver !important;
  }

  .messageSentTime {
    color: $shuttle-grey !important;
  }

  .call-container {
    background-color: $corn-flower-blue;
  }

  .copyButton {
    background: url("/assets/images/message-copy-normal-dark.svg") no-repeat !important;

    &:hover {
      background: url("/assets/images/message-copy-hover-dark.svg") no-repeat !important;
    }

    &:focus,
    &:active {
      background: url("/assets/images/message-copy-pressed-dark.svg") no-repeat !important;
    }
  }

  select.inputField {
    background: url("/assets/images/arrow-down-dark-mode.svg") no-repeat 90%
      17px $black !important;
    border-color: $vulcan !important;
    color: $white !important;
  }

  .submitButton {
    background-color: $tory-blue;
    color: $white-50;

    &:hover,
    &:focus,
    &:active {
      background-color: $mariner !important;
      box-shadow: none !important;
    }
  }

  .contactInfoDropdownContainer {
    &:focus {
      background-color: transparent !important;
    }

    .dropdown-item {
      p {
        color: $pale-blue-dark !important;
      }

      span {
        color: $white !important;
      }
    }
  }

  .messageTextArea::-webkit-scrollbar-thumb,
  .contactsListContainer::-webkit-scrollbar-thumb {
    outline: none;
  }

  .groupDeleteModal {
    .modal-body {
      color: $white;
    }
  }

  .user-name {
    color: $pale-blue-dark;
  }

  .noMessageContainer {
    p {
      color: $raven !important;
    }
  }

  .adminContainer {
    .bodyContainer {
      .bodyHeader {
        h2 {
          color: $raven;
        }
      }
    }

    .contentContainer {
      .table {
        td {
          color: $silver;
        }
      }
    }
  }

  .optionsContainer,
  .contactsListSection {
    &::-webkit-scrollbar {
      width: 0.3em !important;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      background-color: #384f92 !important;
      outline: none !important;
    }
  }

  .processingLoader {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .createItemModal {
    .dataLabel {
      color: $alice-blue;
    }
  }

  .linkButton {
    color: $silver !important;

    &:focus,
    &:active {
      color: $silver !important;
    }
  }

  .pagination {
    .active {
      a,
      span {
        color: $alice-blue !important;
      }

      a:hover,
      span:hover,
      a:focus,
      span:focus {
        color: $ink-blue !important;
      }
    }
  }

  .editContainer {
    .sectionTitle,
    .editFieldsWrap > span {
      color: $alice-blue !important;
    }
  }

  .resetUserPasswordModal {
    .modal-body {
      .dataLabel {
        color: $alice-blue !important;
      }

      .dataValue {
        color: $white-50 !important;
      }
    }
  }

  .errorText {
    color: $red !important;
  }

  .inputField {
    &::placeholder {
      color: $silver !important;
    }

    &:focus {
      color: $alice-blue !important;
    }
  }

  .professionalInformationContainer .multiSelectOptionWithoutCheckbox:hover {
    background-color: #394060;
  }

  // CALENDAR COMPONENT DARK MODE
  .adminContainer {
    background-color: $jaguar;

    .adminComponent {
      .inputField {
        background-color: $east-bay;
        color: $white;
        border-color: $vulcan;

        &::placeholder {
          color: $raven;
        }
      }
    }

    .adminComponent > div:last-child {
      padding-top: 15px;

      @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .searchContainer {
      .inputField {
        background-color: $east-bay;
        color: $white;
        border-color: $vulcan;

        &::placeholder {
          color: $raven;
        }
      }
    }

    .usersContainer {
      .usersTable {
        color: $silver;
      }
    }

    .userLogsContainer {
      .userLogsPage {
        background-color: $jaguar;
        color: $silver;

        .bodyHeader {
          h2 {
            color: $silver;
          }
        }

        .searchContainer {
          .inputField {
            background-color: $east-bay;
            color: $silver;
            border-color: $payne-grey;

            &::placeholder {
              color: $raven;
            }
          }
        }

        .ecsDateRangePicker {
          .rdrDefinedRangesWrapper {
            background-color: $payne-grey;
            border-right: 1px solid #434352;
            border-top: 1px solid #434352;
          }

          .rdrCalendarWrapper {
            background-color: $jaguar;
            color: $silver;
          }

          .rdrDateDisplayWrapper {
            background-color: $east-bay;
          }

          .rdrDateDisplay input {
            color: $silver;
            background-color: $east-bay;
          }

          .rdrMonthAndYearWrapper {
            color: $east-bay;
            background-color: $payne-grey;

            .rdrNextPrevButton {
              color: $silver;
              background-color: $jaguar;
            }

            .rdrPprevButton i {
              border-color: transparent $silver transparent transparent;
            }

            .rdrNextButton i {
              border-color: transparent transparent transparent $silver;
            }
          }

          .rdrMonthAndYearPickers select {
            color: $silver;
            background-color: $east-bay;
          }

          .rdrDay {
            &.rdrDayDisabled {
              background-color: $east-bay;
            }
          }

          .rdrDayNumber span {
            color: $silver;
          }

          .rdrMonth {
            background-color: $payne-grey;
          }

          .rdrWeekDay {
            color: $silver;
          }

          .rdrDay {
            color: $silver;
            
            &:not(.rdrDayPassive) .rdrInRange, 
            &:not(.rdrDayPassive) .rdrStartEdge,
            &:not(.rdrDayPassive) .rdrEndEdge,
            &:not(.rdrDayPassive) .rdrSelected {
              color: $alice-blue;
            }
          }

          .rdrDayPassive, .rdrDayPassive span {
            color: $ink-blue-50;
          }

          .rdrStaticRange {
            background-color: $payne-grey;
            color: $silver;
            border-bottom: 1px solid #434352;

            &:hover, &.rdrStaticRangeSelected {
              background-color: $east-bay;
            }
          }

          .rdrInputRangeInput {
            color: $pale-blue-dark;
            background-color: $jaguar;
          }
        }

        .userLogsList {
          .userLogsTable {
            color: $silver;
            
            th {
              color: $silver;
            }

            td {
              color: $silver;
            }
          }

          .pagination {
            li {
              a {
                color: $silver;
                background-color: $east-bay;

                &:hover {
                  background-color: $tory-blue;
                }
              }

              &.active a {
                background-color: $tory-blue;
              }

              &.disabled a {
                color: $raven;
                background-color: $payne-grey;
              }
            }
          }
        }
      }
    }
  }

}
