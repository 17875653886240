.EulaTextComponent {
    max-width: 760px;
    margin: auto;
  
    h2 {
      font-size: 26px !important;
    }
  
    .btn-content {
      text-align: center;
    }
  
    .draftButton {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  
  .bodyContent {
    position: relative;
  }
  
  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(225 230 238 / 60%);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.modal-dialog {
  width: 600px !important;

  .modal-body {
    padding: 20px !important;
  }
}
