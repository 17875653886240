.RichTextEditor {
  height: 100%;
  width: 100%;
  background-color: #fff;
  max-height: 60vh;

  .ql-container {
    max-height: calc(60vh - 42px); // 42px is roughly the toolbar height
    overflow-y: auto;
  }

  .ql-editor {
    min-height: 100%;
  }

  // Dark mode styles
  &.dark-mode {
    background-color: #1a1a1a;
    color: #fff;

    .ql-toolbar.ql-snow {
      background-color: #2d2d2d;
      border-color: #404040;

      .ql-formats {
        background: #404040;
      }

      .ql-stroke {
        stroke: #fff;
      }

      .ql-fill {
        fill: #fff;
      }

      .ql-picker {
        color: #fff;

        &-label {
          color: #fff;
        }

        &-options {
          background-color: #2d2d2d;
          color: #fff;
        }
      }
    }

    .ql-container.ql-snow {
      border-color: #404040;
    }

    .ql-editor {
      color: #fff;
      background-color: #1a1a1a;

      &.ql-blank::before {
        color: #666;
      }
    }

    blockquote {
      border-left-color: #404040;
      color: #999;
    }
  }

  // Your existing styles...
  >div {
    height: 100%;
    width: 100%;
  }

  blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: Hoefler Text, Georgia, serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .ql-toolbar.ql-snow .ql-formats {
    background: #eee;

    @media (max-width: 768px) {
      margin-bottom: 4px;

      button {
        height: 28px;
        width: 35px;
      }
    }
  }

  iframe {
    display: none;
  }
}