.MFAComponent {
  margin-left: 20% !important;

  @media (max-width: 768px) {
    width: 90vw !important;
    margin: auto !important;
    justify-content: center;
    width: 100% !important;
  }

  &.mfaSetup {
    padding-top: 5vh !important;

    @media (max-width: 768px) {
      margin: auto !important;
      padding: 100px 20px !important;

      max-height: 100vh !important;
    }
  }

  .Intro {
    width: 500px;
    max-width: 100%;
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    position: relative;

    p {
      margin-bottom: 100px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      > * {
        border: none;
        cursor: pointer;
        background-color: #fff;
        font-weight: bold;
      }

      .later {
        color: #bababa;
        font-size: 14px;
      }
    }

    .dismiss {
      border: none;
      cursor: pointer;
      background-color: transparent;
      font-weight: bold;
      color: #313363;
      font-size: 12px;
      position: absolute;
      bottom: -32px;
    }
  }

  .step-container {
    width: 600px;
    max-width: 80vw;
  }

  .step-title {
    margin-bottom: 20px;
  }

  .codeInput {
    position: relative;
  }

  .step-item {
    display: flex;
    margin-bottom: 20px;

    .number {
      margin-right: 10px;
      font-weight: bold;
    }

    .show {
      cursor: pointer;
      font-weight: bold;
      color: #7353e2;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
